import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImageSection from "../components/BackgroundImageSection"
import Typography from "@material-ui/core/Typography"

const Services = () => (
  <Layout>
    <SEO title="Usluge" />
    <BackgroundImageSection
      image="dog"
      id="selidbe"
      heading="Selidbe"
      text={
        <Typography variant="body2" gutterBottom>
          <p>
            Ukoliko se selite u novi dom ili pak ste odlučili da promenite
            poslovni prostor, tu smo da pomognemo.
          </p>
          <p>
            Nudimo kompletne usluge selidbe na području Beograda i Srbije kao i
            selidbe u inostranstvo. Na raspolaganju imamo ambalaže za stvari,
            zaštite za vaš nameštaj i uređaje kao i profesionalno osoblje koje
            će se pobrunuti da vaše stvari neoštećeno stignu do odredišta.
          </p>
          <p>
            Za selidbe u i iz inostranstva nudimo usluge pribavljanja potrebne
            dokumentacije za carinske organe.
          </p>
          <p>
            Cena usluge se formira na osnovu kilometraže između polazne lokacije
            i destinacije, o količini/kilaži stvari, potrebnog osoblja,
            dostupnosti mesta utovara i istovara (lift, prilaz zgradi, itd.),
            potrebnoj ambalaži.
          </p>
        </Typography>
      }
      buttonText="Pozovite odmah"
    />
    <BackgroundImageSection
      image="track"
      id="prevozrobe"
      heading="Prevoz stvari, robe i specijalnog tereta"
      text={
        <Typography variant="body2" gutterBottom>
          <p>
            Bilo da ste fizičko ili pravno lice, ukoliko vam je potreban prevoz
            stvari, paletne robe ili robe specijalnih karakteristika (rashladni
            uređaji, mašine, klaviri, itd.) mi smo pravi izbor za vas.
          </p>
          <p>
            Za prevoz robe i stvari na raspolagaju imamo pickup, kombi, kamion i
            šleper te zavisno od vaših potreba možemo uz dogovor odabrati
            najpovoljniji način transporta.
          </p>
          <p>
            Posebne pogodnosti nudimo pravnim licima kojima je potreban čest
            prevoz robe.
          </p>
        </Typography>
      }
      buttonText="Pozovite odmah"
    />
    <BackgroundImageSection
      image="warehouse"
      id="skladistenje"
      heading="Skladištenje robe"
      text={
        <Typography variant="body2" gutterBottom>
          <p>
            Uz usluge transporta nudimo i usluge skladištenja komercijalne robe
            u našem magacinskom prostoru.
          </p>
        </Typography>
      }
      buttonText=""
    />
    {
      <BackgroundImageSection
        image="logistics"
        id="spedicija"
        heading="Špedicija i logistika"
        text={
          <Typography variant="body2" gutterBottom>
            <p>
              Ukoliko se bavite uvozom i/ili izvozom, nudimo vam kompletnu
              uslugu logistike, transporta, carinjenja i skladištenja po sistemu
              ključ u ruke.
            </p>
            <p>Stalnim saradnicima nudimo specijalne pogodnosti.</p>
          </Typography>
        }
        buttonText=""
      />
    }
  </Layout>
)

export default Services
